import {
    Component,
    Input,
}                              from '@angular/core';

import { IconsMaterial }       from '@Icons/';
import { ButtonBaseComponent } from './button-base.component';


// Values here need to match associated CSS
// export enum BatteryHealthState {
//     good    = 'good',
//     average = 'average',
//     poor    = 'poor',
//     none    = 'none',
//     unknown = 'unknown'
// } // BatteryHealthState


@Component({
   selector:     'qp-battery-level-icon',
   templateUrl:  'battery-level-icon.component.html',
   styleUrls:   ['battery-level-icon.component.css'],
   standalone: false
})
export class BatteryLevelIconComponent extends ButtonBaseComponent
{
    private static readonly battery:         string = "battery_";
    private static readonly batteryChrg:     string = BatteryLevelIconComponent.battery + "charging_";

    private static readonly batteryLevel0:   number = 0;
    private static readonly batteryLevel20:  number = 20;
    private static readonly batteryLevel30:  number = 30;
    private static readonly batteryLevel50:  number = 50;
    private static readonly batteryLevel60:  number = 60;
    private static readonly batteryLevel80:  number = 80;
    private static readonly batteryLevel90:  number = 90;
    private static readonly batteryLevel100: number = 100;


    private static readonly batteryIconStr = {
        alert:       BatteryLevelIconComponent.battery + "alert",
        charging: {
            [BatteryLevelIconComponent.batteryLevel20]:  BatteryLevelIconComponent.batteryChrg + [BatteryLevelIconComponent.batteryLevel20],
            [BatteryLevelIconComponent.batteryLevel30]:  BatteryLevelIconComponent.batteryChrg + [BatteryLevelIconComponent.batteryLevel30],
            [BatteryLevelIconComponent.batteryLevel50]:  BatteryLevelIconComponent.batteryChrg + [BatteryLevelIconComponent.batteryLevel50],
            [BatteryLevelIconComponent.batteryLevel60]:  BatteryLevelIconComponent.batteryChrg + [BatteryLevelIconComponent.batteryLevel60],
            [BatteryLevelIconComponent.batteryLevel80]:  BatteryLevelIconComponent.batteryChrg + [BatteryLevelIconComponent.batteryLevel80],
            [BatteryLevelIconComponent.batteryLevel90]:  BatteryLevelIconComponent.batteryChrg + [BatteryLevelIconComponent.batteryLevel90],
            [BatteryLevelIconComponent.batteryLevel100]: BatteryLevelIconComponent.batteryChrg + "full"
        },
        notcharging:    {
            [BatteryLevelIconComponent.batteryLevel20]:  BatteryLevelIconComponent.battery     + [BatteryLevelIconComponent.batteryLevel20],
            [BatteryLevelIconComponent.batteryLevel30]:  BatteryLevelIconComponent.battery     + [BatteryLevelIconComponent.batteryLevel30],
            [BatteryLevelIconComponent.batteryLevel50]:  BatteryLevelIconComponent.battery     + [BatteryLevelIconComponent.batteryLevel50],
            [BatteryLevelIconComponent.batteryLevel60]:  BatteryLevelIconComponent.battery     + [BatteryLevelIconComponent.batteryLevel60],
            [BatteryLevelIconComponent.batteryLevel80]:  BatteryLevelIconComponent.battery     + [BatteryLevelIconComponent.batteryLevel80],
            [BatteryLevelIconComponent.batteryLevel90]:  BatteryLevelIconComponent.battery     + [BatteryLevelIconComponent.batteryLevel90],
            [BatteryLevelIconComponent.batteryLevel100]: BatteryLevelIconComponent.battery     + "full"
        },
        standard:    BatteryLevelIconComponent.battery + "std",
        unknown:     BatteryLevelIconComponent.battery + "unknown"
    }; // batteryIconStr


    private _charging: boolean = false;
    private _health:   string  = "";
    private _icon:     string  = "";
    private _level:    number;

    public str:        string  = "";


    public constructor()
    {
        super();

        this.update();
    }


    //
    // Getters and setters
    //
    @Input()
    public get charging(): boolean 
    {
        return this._charging;
    }

    public set charging(d: boolean)
    {
        this._charging = d;
        this.update();
    }


    @Input()
    public get health(): string
    {
        return this._health;
    }

    public set health(d: string)
    {
        this._health = d;
        // this.update();
    }


    public get icon(): string 
    {
        return this._icon;
    }

    private set iconI(d: string)
    {
        this._icon = d;
    }


    @Input()
    public get level(): number
    {
        return this._level;
    }

    public set level(d: number)
    {
        this._level = d;
        this.update();
    }


    // public get tooltip(): string
    // {
    //     return (
    //           "Level: "      + (this.level >= 0 ? this.level + "%" : "unknown")
    //         + ", charging: " + (this.charging ? "yes" : "no")
    //         + ", health: "   + this.health
    //     );
    // }


    //
    // Static methods
    //
    private static getBatteryIcon(charging: boolean, level: number): string
    {
        level=-1;// [TBD]
        let i: string | undefined = this.batteryIconStr.unknown;

        if (level != undefined) {
            if      (level >= BatteryLevelIconComponent.batteryLevel100) {
                i = this.batteryIconStr[charging ? 'charging' : 'notcharging'][BatteryLevelIconComponent.batteryLevel100];
            }
            else if (level >= BatteryLevelIconComponent.batteryLevel80) {
                i = this.batteryIconStr[charging ? 'charging' : 'notcharging'][BatteryLevelIconComponent.batteryLevel80];
            }
            else if (level >= BatteryLevelIconComponent.batteryLevel60) {
                i = this.batteryIconStr[charging ? 'charging' : 'notcharging'][BatteryLevelIconComponent.batteryLevel60];
            }
            else if (level >= BatteryLevelIconComponent.batteryLevel50) {
                i = this.batteryIconStr[charging ? 'charging' : 'notcharging'][BatteryLevelIconComponent.batteryLevel50];
            }
            else if (level >= BatteryLevelIconComponent.batteryLevel30) {
                i = this.batteryIconStr[charging ? 'charging' : 'notcharging'][BatteryLevelIconComponent.batteryLevel30];
            }
            else if (level >= BatteryLevelIconComponent.batteryLevel20) {
                i = this.batteryIconStr[charging ? 'charging' : 'notcharging'][BatteryLevelIconComponent.batteryLevel20];
            }
            else if (level >= BatteryLevelIconComponent.batteryLevel0) {
                i = this.batteryIconStr.alert;
                // if (charging) i = this.batteryIconStr.alert;
            }

            // [TBD] Level-based icons above aren't hosted by Google
            if (level < BatteryLevelIconComponent.batteryLevel100) {
                i = charging ? BatteryLevelIconComponent.batteryChrg + "full" : this.batteryIconStr.standard;
            }
        }
        
        return i ? i : this.batteryIconStr.unknown;
    }


    //
    // Private methods
    //
    private update(): void
    {
        this.iconI = BatteryLevelIconComponent.getBatteryIcon(this.charging, this.level);
    }
}