import {
    Component,
    Input,
}                              from '@angular/core';

import { IconsMaterial }       from '@Icons/';
import {
    MobileCell,
    MobileCellTechnology
}                              from '@ObjElements/mobile-cells/';

import { ButtonBaseComponent } from './button-base.component';


@Component({
   selector:     'qp-mobilecell-technology-icon',
   templateUrl:  'mobilecell-technology-icon.component.html',
   styleUrls:   ['mobilecell-technology-icon.component.css'],
   standalone: false
})
export class MobileCellTechnologyIconComponent extends ButtonBaseComponent
{
    private static readonly _icons: Record<string, string> = {
        [MobileCellTechnology.cdma]:     IconsMaterial.mobile_technology_3g,
        [MobileCellTechnology.gsm]:      IconsMaterial.mobile_technology_2g,
        [MobileCellTechnology.lte]:      IconsMaterial.mobile_technology_4g,
        [MobileCellTechnology.nr]:       IconsMaterial.mobile_technology_5g,
        [MobileCellTechnology.nr_nsa]:   IconsMaterial.mobile_technology_5g,
        [MobileCellTechnology.td_scdma]: IconsMaterial.mobile_technology_4g,
        [MobileCellTechnology.umts]:     IconsMaterial.mobile_technology_3g
    }; // _icons

    private _icon:             string = "";
    private _technology:       string = "";
    private _technologydetail: string = "";



    // const cats: Record<CatBreed, CatInfo[]> = {
    //     bobtail: [{ age: 7, name: "miffy" }],
    //     birman: [{ age: 8, name: "moorzik" }],
    //     maine: [{ age: 17, name: "mordred" }],
    //   };


    @Input()
    public  mobilecell:  MobileCell;


    //
    // Getters and setters
    //
    @Input()
    public get icon(): string
    {
        return (this._icon)
            ? this._icon
            : MobileCellTechnologyIconComponent.getIcon(
                this.technology,
                this.technologydetail
            ); // getIcon
    }

    public set icon(d)
    {
        this._icon = d;
    }


    @Input()
    public get technology(): string
    {
        return (this._technology)
            ? this._technology
            : ((this.mobilecell instanceof MobileCell)
                ? this.mobilecell.technologyType
                : this._technology
            );
    }

    public set technology(d: string)
    {
        this._technology = d;
    }


    @Input()
    public get technologydetail(): string
    {
        return (this._technologydetail)
            ? this._technologydetail
            : ((this.mobilecell instanceof MobileCell)
                ? this.mobilecell.technologydetail
                : this._technologydetail
            );
    }

    public set technologydetail(d: string)
    {
        this._technologydetail = d;
    }


    //
    // Public methods
    //
    public getCssClass(): string
    {
        return (this.technology === MobileCellTechnology.lte)
            ? (this.is5g() ? MobileCellTechnology.nr_nsa : this.technology)
            : this.technology;
    }


    //
    // Private methodss
    //
    private static getIcon(d: string | MobileCell, e?: string): string
    {
        //     return (d instanceof MobileCell) ? MobileCellHelper.getIcon(d) : "";

        const t:  string = (d instanceof MobileCell) ? d.technology       : d;
        const td: string = (d instanceof MobileCell) ? d.technologydetail : e;

        const i: string | undefined = (t === MobileCellTechnology.lte)
            ? (MobileCellTechnologyIconComponent.is5g(e)
                ? IconsMaterial.mobile_technology_5g
                : (MobileCellTechnologyIconComponent.is4gplus(e)
                    ? IconsMaterial.mobile_technology_4gplus
                    : IconsMaterial.mobile_technology_4g
                )
            )

            : MobileCellTechnologyIconComponent._icons[t];

        return (i) ? i : IconsMaterial.mobile_cells;

        // switch (t) {
        //     case MobileCellTechnology.cdma:     return IconsMaterial.mobile_technology_3g;
        //     case MobileCellTechnology.gsm:      return IconsMaterial.mobile_technology_2g;


        //     // [TBD]
        //     case MobileCellTechnology.lte:
        //         return MobileCellTechnologyIconComponent.is5g(e)
        //             ? IconsMaterial.mobile_technology_5g
        //             : (MobileCellTechnologyIconComponent.is4gplus(e)
        //                 ? IconsMaterial.mobile_technology_4gplus
        //                 : IconsMaterial.mobile_technology_4g
        //             );
        //     case MobileCellTechnology.nr:       return IconsMaterial.mobile_technology_5g;
        //     case MobileCellTechnology.nr_nsa:   return IconsMaterial.mobile_technology_5g;
        //     case MobileCellTechnology.td_scdma: return IconsMaterial.mobile_technology_4g;
        //     case MobileCellTechnology.umts:     return IconsMaterial.mobile_technology_3g;
        //     default:                            return IconsMaterial.mobile_cells;
        // } // switch
    }


    private static is4gplus(d: string | undefined): boolean
    {
        return typeof d === "string" && d.startsWith('ca');
    }

    
    private is5g(): boolean
    {
        return MobileCellTechnologyIconComponent.is5g(this.technologydetail);
    }


    private static is5g(d: string | undefined): boolean
    {
        return typeof d === "string" && d.startsWith(MobileCellTechnology.nr);
    }
}