import {
    Component,
    EventEmitter,
    Input,
    Output
}                               from '@angular/core';

import { BaseComponent }        from '@Base/';
import { ElementState }         from '@ObjElements/element/element/';

import { StatusIndicatorState } from './status-indicator.component';


@Component({
   selector:     'qp-window-header',
   templateUrl:  'window-header.component.html',
   styleUrls:   ['window-header.component.css'],
   standalone: false
})
export class WindowHeaderComponent extends BaseComponent
{
    public readonly rtBack:    boolean                              = false;


    @Input()
    public geolocationEnabled: boolean                              = false;

    @Input()
    public refreshEnabled:     boolean                              = true;

    @Input()
    public routeBack:          string[];

    @Input()
    public state:              ElementState | StatusIndicatorState;

    @Input()
    public statusEnabled:      boolean                              = false;

    @Input()
    public title:              string                               = "";

    @Input()
    public value:              number | string;


    @Output()
    public geolocation:        EventEmitter<boolean>                = new EventEmitter(); // used to inform parents that button pressed

    @Output()
    public refresh:            EventEmitter<boolean>                = new EventEmitter(); // used to inform parents that button pressed


    //
    // Public functions
    //
    public geolocationFn(): void
    {
        this.geolocation.emit(true);
    }


    public refreshFn(): void // can't use name refresh() as clashes with EventEmitter of same name
    {
        this.refresh.emit(true);
    }
}