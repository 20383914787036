import {
    Component,
    Input
}                        from '@angular/core';

import { BaseComponent } from '@Base/';


@Component({
   selector:     'qp-title',
   templateUrl:  'title.component.html',
   styleUrls:   ['title.component.css'],
   standalone: false
})
export class TitleComponent extends BaseComponent
{
    @Input()
    public icon:  string = "";

    @Input()
    public title: string = "";

    @Input()
    public value: number = 0;
}