import {
    Component,
    // ComponentFactoryResolver,
    EnvironmentInjector,
    Inject,
    Injector,
    Input,
    ViewChild,
    ViewContainerRef
}                            from '@angular/core';
import {
    MAT_DIALOG_DATA,
    
    MatDialogRef
}                            from "@angular/material/dialog";

import {
    BaseComponent,
    BaseWithDynamicComponent
}                            from '@Base/';


export interface DialogComponentDataI {
    component?:          typeof BaseComponent,
    data?:               any,
    disableCloseButton?: boolean,
    enableOkButton?:     boolean,
    injector?:           Injector
}


@Component({
    selector:    'qp-dialog',
    templateUrl: 'dialog.component.html',
    styleUrls:   ['dialog.component.css'],
    standalone:  false
})
export class DialogComponent extends BaseWithDynamicComponent
{
    @ViewChild('container', { static: true, read: ViewContainerRef })
    container: ViewContainerRef;

    
    // Disable close button
    @Input()
    public disableCloseButton: boolean = false;

    @Input()
    public enableOkButton:     boolean = false;

    @Input()
    public injector:           EnvironmentInjector | Injector | undefined;


    // public constructor(                 ComponentFactoryResolver: ComponentFactoryResolver,
    public constructor(private readonly MatDialogRef: MatDialogRef<any>,

                       @Inject(MAT_DIALOG_DATA)
                       private readonly data:         DialogComponentDataI)
    {
        // super(ComponentFactoryResolver);
        super();
    }


    // Override
    public override ngOnInit(): void // can't do in ngAfterViewInit() as will give ExpressionChangedAfterItHasBeenCheckedError
    {
        super.ngOnInit();

        // Must do this in ngOnInit() as ViewChild not ready until then
        if (this.data) {
            const lData = this.data as DialogComponentDataI;

            this.disableCloseButton = !! lData.disableCloseButton;
            this.enableOkButton     = !! lData.enableOkButton;
            this.injector           = lData.injector;

            // Do at end
            if (lData.component) this.component = lData.component; // will trigger dynamic component creation
        }
    }

        
    //
    // Getters and setters
    //
    @Input()
    public set component(t: typeof BaseComponent | undefined)
    {
        if (t) this.open(t, (this.data as DialogComponentDataI)?.data);
        else   this.close();
    }


    //
    // Public methods
    //

    // Override
    public override close(data?: any): void
    {
        if (this.MatDialogRef instanceof MatDialogRef) {
            // Listen for close event
            this.MatDialogRef.afterClosed().subscribe((d: any | undefined) => {
                if (this.container?.clear instanceof Function) this.container.clear();
            }); // subscribe
            this.MatDialogRef.close(data);
        }

        super.close();
    }


    public ok(): void
    {
        this.close(true); // true to mark as triggered by 'ok'
    }


    //
    // Private methods
    //
    private open(t: typeof BaseComponent | undefined, d?: any): void
    {
        super.openT(this.container, t, d, this.injector as EnvironmentInjector);
  
        this.compRef?.instance?.selected?.subscribe((d: any) => {
            this.close()
        }); // subscribe
    }
}