import {
    Component,
    Input
}                        from '@angular/core';

import { BaseComponent } from '@Base/';


@Component({
    selector:     'qp-table-data',
    templateUrl:  'table-data.component.html',
    styleUrls:   ['table-data.component.css'],
    standalone: false
})
export class TableDataComponent extends BaseComponent
{
    public readonly spaceKey:   string  = "35%";
    public readonly spaceValue: string  = "65%";


    private         _data:      any     = undefined;


    @Input()
    private         nullValues: boolean = true;


    // Getters and setters
    @Input()
    public get data(): any
    {
        return Array.isArray(this._data)
            ? this._data.filter(d => d && d.k && (this.nullValues || d.v))
            : this._data;
    }


    public set data(d: any)
    {
        this._data = d;
    }
}