import { Component }               from '@angular/core';
import { 
    Observable,
    Subscription,

    take
}                                  from 'rxjs';

// import {
//     DevicesService
// }                                  from '@Devices/devices.service'; // needed
import { DeviceCollectionService } from '@Devices/devices-active.service'; // needed
import { DevicesListComponent }    from '@Devices/devices-active.component'; // needed
import { IconsMaterial }           from '@Icons/';
import {
    ClientConfigService,
    DataService,
    DataServiceEvents,
    DialogService
}                                  from '@Misc/Services/';
import { 
    DeviceCommon,
    DeviceTypes
}                                  from '@ObjElements/devices/device/';
import { Utils }                   from '@Utils/';

import {
    HeaderBadgeComponent,
    HeaderBadgeComponentData
}                                  from './header-badge.component';


@Component({
    selector:     'qp-header-devices',
    templateUrl:  'header-devices.component.html',
    styleUrls:   ['header-devices.component.css'],
    standalone: false
})
export class HeaderDevicesComponent extends HeaderBadgeComponent
{
    public                      deviceMobilephonesOnly: boolean      = false;
    public                      refresh:                boolean      = false;
    public             readonly iconDevices:            string       = IconsMaterial.devices_online;
    public             readonly iconRefresh:            string       = IconsMaterial.refresh;

    private static     readonly DoRefresh:              boolean      = false;

    // Override
    // protected override readonly mDialogHeight: string = AboutComponent.dialogHeight
    // Override
    protected override readonly mDialogWidth:           string       = "45em";

    private                     _nums                                = new Map();
    private                     _sub2:                  Subscription = new Subscription();
    private            readonly _subs                                = new Map();

    private                     _srv:                   DataService;


    //public ss: string = "⟳" // use for refreshing sybmol?

    
    constructor(private readonly ClientConfigService:     ClientConfigService,
                private readonly DeviceCollectionService: DeviceCollectionService,
                // private readonly DevicesService:          DevicesService, 
                                 DialogService:           DialogService)
    {
        super(DialogService);

        if (this.ClientConfigService) {
            this.sub = this.ClientConfigService.deviceMobilephonesOnly$
                .subscribe((d: boolean): void => {
                    this.deviceMobilephonesOnly = d;
                }); // subscribe

            // this.sub = this.ClientConfigService.refreshButtonEnabled$
                //.subscribe((d: boolean): void => {
                //     this.refresh = d;
                // }); // subscribe
        }

        this._srv = DeviceCollectionService;//DevicesService; //DeviceCollectionService;
    }


    // // Override - don't let base class call this.initialise()
    // public ngOnInit(): void
    // {
    //     // Call this.initialise() in ngAfterContentInit() to ensure view fully set up
    // }


    // // Override
    // public ngAfterContentInit(): void // don't use ngAfterViewInit() as that can produce ExpressionChangedAfterItHasBeenCheckedError error
    // {
    //     super.ngAfterContentInit();

    //     // Do here to call only after view fully set up
    //     this.initialise();
    // }


    //
    // Getters and setters
    //
    public get size(): number
    {
        return (this.deviceMobilephonesOnly)
            ? this._nums.get(DeviceTypes.mobilephone)
            : Object.keys(this._nums).length;//this.DeviceCollectionService ? this.DeviceCollectionService.size : 0;
    }


    protected get sub2(): Subscription
    {
        return this._sub2;
    }

    protected set sub2(s: Subscription | undefined)
    {
        if (this._sub2 instanceof Subscription) {
            if (s instanceof Subscription) {
                this._sub2.add(s);
            }
            else {
                this._sub2.unsubscribe();
                this._sub2 = new Subscription();
            }
        }
    }


    public get tooltip(): string
    {
        const tooltipStrStart = "Active Devices - ";

        let tooltipStr: string = "";
        [...this._nums.keys()].sort().forEach((d: string): void => {
            if (! this.deviceMobilephonesOnly || DeviceTypes.mobilephone === d) {
                tooltipStr += d + ": " + this._nums.get(d) + "\n";
            }
        }); // forEach
        
        return tooltipStrStart + (tooltipStr ? tooltipStr : "none");
    }


    //
    // Protected functions
    //

    // Override
    protected override cleanUp(): void
    {
        super.cleanUp();

        this.sub2 = undefined;
        this.refresh = false;
        // if (this._sub2 instanceof Subscription) this._sub2.unsubscribe();

        this._subs.forEach((s: Subscription): void => {
            if (s instanceof Subscription) s.unsubscribe();
        }); // forEach
    }


    // Override
    protected override initialise(): void
    {
        super.initialise();

        // For below subscriptions, don't use 'this.sub' for this as it will be cleared by parent processData()

        // Used to change displayed icon if service is (re-)loading data
        // let obs: Observable<any> | undefined = this.DeviceCollectionService[DataServiceEvents.loading];
        let obs: Observable<any> | undefined = this._srv[DataServiceEvents.loading];
        if (obs instanceof Observable) this.sub2 = obs
            .subscribe((d: boolean): void => {
                if (HeaderDevicesComponent.DoRefresh) Utils.delaySecs(() => {
                    this.refresh = d;
                    // if (this.refresh = d) this._nums = {};
                }); // delaySecs, workaround for ExpressionChangedAfterItHasBeenCheckedError error sometimes seen

                // window.setTimeout(() => this.refresh = d); // workaround for ExpressionChangedAfterItHasBeenCheckedError error sometimes seen
            }); // subscribe

        obs = this._srv[DataServiceEvents.status]
        if (obs instanceof Observable) this.sub2 = obs
            .pipe(
                take(1)
            )
            .subscribe((d: object): void => { // DeviceCollectionService returns object here unlike other services
                if (d) Object.keys(d).forEach((s: string): void => {
                    this._addSub(s, false)
                }); // forEach
            }); // subscribe

        // obs = this.DeviceCollectionService[DataServiceEvents.added];
        obs = this._srv[DataServiceEvents.added];
        if (obs instanceof Observable) this.sub2 = obs
            .subscribe((d: string): void => {
                this._addSub(d);
            }); // subscribe

        // obs = this.DeviceCollectionService[DataServiceEvents.deleted];
        obs = this._srv[DataServiceEvents.deleted];
        if (obs instanceof Observable) this.sub2 = obs
            .subscribe((d: string): void => {
                if (d) {
                    if (this._subs.get(d) instanceof Subscription) this._subs.get(d).unsubscribe();
                    this._subs.delete(d);
                    this._nums.delete(d);
                    if (d === DeviceCommon.deviceTypes.mobilephone) this.update(0); // [TBD]
                    //if (d == DeviceCommon.deviceTypes.mobilephone) this.data = this.getData(undefined);
                }
            }); // subscribe
    }


    // Override
    protected override getData(): HeaderBadgeComponentData
    {
        return {
            name:       "HeaderDevices",
            component:  DevicesListComponent,
            observable: undefined
        }; // return
    }


    //
    // Private functions
    //
    private _addSub(d: string, updateColour: boolean = true): void
    {
        if (d) {
            // Unsubscribe if already set
            if (this._subs.get(d) instanceof Subscription) this._subs.get(d).unsubscribe();
            this._subs.delete(d);
            this._nums.delete(d);

            const obs: Observable<number> =
                this._srv?.getObservable(d, DataServiceEvents.number) as Observable<number>;
                // this.DeviceCollectionService.getObservable(d, DataServiceEvents.number) as Observable<number>;
            if (obs instanceof Observable) {
                //if (! this.data) this.data = this.getData(undefined);
                this._subs.set(d, obs
                    .subscribe((v: number): void => {
                        if (DeviceCommon.deviceTypes.mobilephone === d) this.update(v, true); // [TBD]
                        this._nums.set(d, v);
                    }) // subscribe
                ); // set

                // this._subs.set(d, this._srv?.deviceAdded$
                // this._subs[d] = this.DeviceCollectionService.deviceAdded$
                let obs2: Observable<any> | undefined= this._srv?.getObservable("", DataServiceEvents.added)
                if (obs2 instanceof Observable) this._subs.set(
                    d,
                    obs2
                    .subscribe((v: string): void => {
                        // if (DeviceCommon.deviceTypes.mobilephone === v)
                            this.updateColour(HeaderBadgeComponent.colourIncrease);
                    }) // subscribe
                ); // set

                // this._subs.set(d, this._srv?.deviceDeleted$
                // this._subs.set(d, this._srv?.getObservable(DataServiceEvents.deleted)
                obs2 = this._srv?.getObservable("", DataServiceEvents.deleted)
                if (obs2 instanceof Observable) this._subs.set(
                    d,
                    obs2
                    .subscribe((v: string): void => {
                        // if (DeviceCommon.deviceTypes.mobilephone === v)
                            this.updateColour(HeaderBadgeComponent.colourDecrease);
                    }) // subscribe
                ); // set
            }
        }
    }
}