import { Component }           from '@angular/core';

import { IconsMaterial }       from '@Icons/';
import { AboutComponent }      from '@Misc/Components/';
import { DialogService }       from '@Misc/Services/';

import { HeaderBaseComponent } from './header-base.component';


@Component({
    selector:     'qp-header-about',
    templateUrl:  'header-about.component.html',
    styleUrls:   ['header-about.component.css'],
    standalone: false
})
export class HeaderAboutComponent extends HeaderBaseComponent
{
    public readonly icon: string = IconsMaterial.help;


    constructor(DialogService: DialogService)
    {
        super(DialogService);
    }


    //
    // Getter
    //

    // Override
    protected override get component(): any
    {
        return AboutComponent;
    }
}