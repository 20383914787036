import {
    Component,
    Input
}                              from '@angular/core';

import { IconsMaterial }       from '@Icons/';

import { ButtonBaseComponent } from './button-base.component';
import { ButtonComponent }     from './button.component';


@Component({
   selector:    'qp-report-button',
   templateUrl: 'report-button.component.html',
   styleUrls:   [ 'report-button.component.css'],
   imports:     [ ButtonComponent ]
//    standalone: false
})
export class ReportButtonComponent extends ButtonBaseComponent
{
    public readonly icon: string = IconsMaterial.report;
}
