import { Component }           from '@angular/core';

import { IconsMaterial }       from '@Icons/';

import { ButtonBaseComponent } from './button-base.component';


@Component({
   selector:    'qp-image-button',
   templateUrl: 'image-button.component.html',
   styleUrls:   [ 'image-button.component.css' ],
   standalone:  false
})
export class ImageButtonComponent extends ButtonBaseComponent
{
   public readonly icon: string = IconsMaterial.image;
}