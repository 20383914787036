<!-- <mat-form-field appearance="outline">
    <mat-select [formControl]="selectedFW"> -->
<mat-card>
    <mat-card-header>
<!--     
</div> fxLayout="row" fxLayoutAlign="space-between center"> -->
    <!-- <div fxFlexAlign="start">
        <h3 mat-dialog-title>{{ name }}</h3>
    </div>

    <div fxFlexAlign="end center">
        <a href="{{ cd.webUrl }}" target="_blank" class="image-png">
            <img src="{{ cd.icon }}" height="50vw" alt="{{ company }}">
        </a>
    </div> -->


    <!-- <div fxFlexAlign="start"> -->
        <mat-card-title-group>
            <a href="{{ cd.webUrl }}" target="_blank" class="image-png">
                <img mat-card-sm-image src="{{ cd.icon }}" height="50vw" alt="{{ company }}">
            </a>

            <mat-card-title>
                <div class="mat-headline">
                    {{ name }}
                </div>
            </mat-card-title>
        </mat-card-title-group>

    <!-- </div> -->
    </mat-card-header>


    <mat-card-content>
        <mat-list role="list" dense> <!--dense-->
            <mat-list-item role="listitem">
                <div matLine>
                <!-- A15+ <span matListItemTitle> -->
                    &copy; {{ today ? today.getFullYear() : '' }} {{ company }}
                </div>
                <div matLine>
                    All rights reserved
                </div>
            </mat-list-item>


            <mat-list-item role="listitem">
                <div matLine>
                    Version: {{ version }}
                </div>

                <div matLine *ngIf="cDate">
                    Build date: {{ cDate }}
                </div>

                <div matLine *ngIf="showAngularVersion && angularVersion">
                    Built on Angular: {{ angularVersion }}
                </div>
            </mat-list-item>


            <div mat-subheader></div>


            <!-- <mat-list-item ro1le="listitem" class="wrapper">
                <div matLine>
                    Version: {{ version }}
                </div>

                <div matLine *ngIf="showAngularVersion && angularVersion">
                    Built on Angular: {{ angularVersion }}
                </div>

                <div matLine *ngIf="cDate">
                    Build date: {{ cDate }}
                </div>
            </mat-list-item> -->


            <!-- <mat-list-item role="listitem" *ngIf="showAngularVersion && angularVersion" class="wrapper">
                Built on Angular: {{ angularVersion }}
            </mat-list-item>


            <mat-list-item role="listitem" *ngIf="cDate" class="wrapper">
                Build date: {{ cDate }}
            </mat-list-item> -->
            <!-- new Date(VersionService.getTimestamp()).toISOString() }}) -->

            <!-- <br><br> -->


            <!-- <mat-list-item role="listitem" class="wrapper">
                <div matLine>
                    <a href="{{ cd.emailUrl }}" target="_blank">
                        <mat-icon class="image-txt">{{ iconEmail }}</mat-icon>
                        {{ cd.email }}
                    </a>
                </div>

                <div matLine>
                    <a href="{{ cd.webUrl }}" target="_blank">
                        <mat-icon class="image-txt">{{ iconWebLink }}</mat-icon>
                        {{ companyUrl }}
                    </a>
                </div>
            </mat-list-item> -->

            <mat-list-item role="listitem">
 
                <div matLine *ngIf="showEmail">
                    <a href="{{ cd.emailUrl }}" target="_blank">
                        <button mat-menu-item> 
                            <mat-icon mat-list-icon>{{ iconEmail }}</mat-icon>
                            <span>
                                {{ cd.email }}
                            </span>    
                        </button>                    
                    </a>
                </div>

            <!-- </mat-list-item> -->


            <!-- <mat-list-item role="listitem"> -->
                <div matLine *ngIf="showUrl">
                    <a href="{{ cd.webUrl }}" target="_blank">
                        <button mat-menu-item> 
                            <mat-icon mat-list-icon>{{ iconWebLink }}</mat-icon>
                            <span>
                                {{ companyUrl }}
                            </span>    
                        </button>                    
                    </a>
                </div>
            </mat-list-item>
        </mat-list>

    </mat-card-content>

<!-- </mat-select>
</mat-form-field> -->
</mat-card>