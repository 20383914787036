import { Component }                 from '@angular/core';

import { IconsMaterial }             from '@Icons/';
import { DialogService }             from '@Misc/Services/';
import { NotificationsOldComponent } from '@Notifications/notifications-old.component';
import { NotificationsService }      from '@Notifications/notifications.service';

import {
    HeaderBadgeComponent,
    HeaderBadgeComponentData
}                                    from './header-badge.component';


@Component({
    selector:     'qp-header-notifications',
    templateUrl:  'header-notifications.component.html',
    styleUrls:   ['header-notifications.component.css'],
    standalone: false
})
export class HeaderNotificationsComponent extends HeaderBadgeComponent
{
    public readonly icon: string = IconsMaterial.notifications;


    constructor(                 DialogService:        DialogService,
                private readonly NotificationsService: NotificationsService)
    {
        super(DialogService);

        //this.data = this.getData(this.NotificationsService.updatedNum);
    }


    //
    // Protected functions
    //

    // Override
    protected override getData(): HeaderBadgeComponentData
    {
        return {
            name:       "HeaderNotifications",
            component:  NotificationsOldComponent,
            observable: this.NotificationsService.updatedNum
        };
    }
}