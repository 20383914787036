import { MediaMatcher }        from '@angular/cdk/layout';
import {
    Component,
    HostBinding
}                              from '@angular/core';

import {
    DialogService,
    DisplayModeService
}                              from '@Misc/Services/';
import { IconsMaterial }       from '@Icons/';

import { HeaderBaseComponent } from './header-base.component';


@Component({
    selector:     'qp-header-display-mode',
    templateUrl:  'header-display-mode.component.html',
    styleUrls:   ['header-display-mode.component.css'],
    standalone: false
})
export class HeaderDisplayModeComponent extends HeaderBaseComponent
{
    public readonly iconDark:  string = IconsMaterial.dark_mode;
    public readonly iconLight: string = IconsMaterial.light_mode;


    constructor(                 DialogService:      DialogService,
                private readonly DisplayModeService: DisplayModeService,
                private readonly Media:              MediaMatcher)
    {
        super(DialogService);
    }



    // Override
    public override onClick(event: any): void
    {
        this.toggle();
    }

    // @HostBinding('class')
    // public get themeMode() {
    // //   return this.darkModeUI ? 'dark-theme' : 'light-theme';
    // }


    public isDark(): boolean
    {
        return !! document?.body?.classList?.contains('theme-dark');
    }



    private toggle(): void
    {
        if (this.DisplayModeService) this.DisplayModeService.update(! this.isDark());
    }
}