import { Component }                 from '@angular/core';

import { ClientConfigService }       from '@Misc/Services/';

import { HeaderFooterBaseComponent } from './header-footer-base.component';


@Component({
   selector:     'qp-footer',
   templateUrl:  'footer.template.html',
   styleUrls:   ['footer.component.css'],
   standalone: false
})
export class FooterComponent extends HeaderFooterBaseComponent
{
    constructor(ClientConfigService: ClientConfigService)
    {
        super(ClientConfigService);
    }


    //
    // Protected functions
    //
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising Footer component");
    }
}