import { Component }           from '@angular/core';

import { IconsMaterial }       from '@Icons/';

import { ButtonBaseComponent } from './button-base.component';


@Component({
   selector:     'qp-map-button',
   templateUrl:  'map-button.component.html',
   styleUrls:   ['map-button.component.css'],
   standalone: false
})
export class MapButtonComponent extends ButtonBaseComponent
{
   public readonly icon: string = IconsMaterial.map;
}