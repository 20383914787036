import {
    Component,
    EventEmitter,
    Output
}                              from '@angular/core';

import { ButtonBaseComponent } from './button-base.component';


@Component({
    template: '',
    standalone: false
})
export class MenuComponent extends ButtonBaseComponent
{
    @Output()
    public selected: EventEmitter<string> = new EventEmitter(); // used to inform parents that button pressed


    //
    // Public functions
    //
    public select($event: MouseEvent, state: string): void
    {
        this.selectFn($event, state);
    }


    //
    // Private functions
    //
    private selectFn($event: MouseEvent, state: string): void
    {
        this.selected.emit(state);
    }
}