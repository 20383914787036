import { Component }       from '@angular/core';
import { Observable }      from 'rxjs';

import { BaseComponent }   from '@Base/';
import { IconsMaterial }   from '@Icons/';
import {
    AuthenticationService,
    User,
    UserService
}                          from '@Login/';


@Component({
    selector:     'qp-header-user',
    templateUrl:  'header-user.component.html',
    styleUrls:   ['header-user.component.css'],
    standalone: false
})
export class HeaderUserComponent extends BaseComponent
{
    public readonly iconArrowDown: string = IconsMaterial.arrow_drop_down;
    public readonly iconLogout:    string = IconsMaterial.logout;
    public readonly iconPerson:    string = IconsMaterial.person;
    public readonly iconUser:      string = IconsMaterial.user;

    // [TBD]
    public user: User;
    public name: string;


    constructor(private readonly AuthenticationService: AuthenticationService,
                private readonly UserService:           UserService)
    {
        super();
    }


    //
    // Public functions
    //
    public logout(event: any): void
    {
        //console.log("Logout menu item selected");   
        this.AuthenticationService.logout();
    }


    public open(event: any): void
    {
        //console.log("Button pressed - displaying User");
        this.show();
    }


    //
    // Protected functions
    //

    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising HeaderSettings User");

        this.user = new User();

        if (this.UserService && this.UserService.user$ instanceof Observable) {
            this.sub = this.UserService.user$.subscribe((user: User | undefined): void => {
                if (user instanceof User) this.update(user);
            }); // subscribe
        }
    }


    //
    // Private functions
    //
    private show(): void
    {
        console.debug("[TBD] showing User");
    }


    private update(user: User): User
    {
        return (this.user = user ? user : new User()); // HTML needs access to member so ensure object is present
    }
}