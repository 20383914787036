import {
   Component,
   Input
}                            from '@angular/core';

import { BaseComponent }     from '@Base/';

import {
   MatProgressSpinnerModule,
   ProgressSpinnerMode
}                            from '@Material/';


@Component({
   selector:    'qp-progress-spinner',
   templateUrl: 'progress-spinner.component.html',
   styleUrls:   [ 'progress-spinner.component.css' ],
   imports:     [ MatProgressSpinnerModule ]
   // standalone:  false
})
export class ProgressSpinnerComponent extends BaseComponent
{
   @Input()
   public diameter: number              = 100;

   @Input()
   public mode:     ProgressSpinnerMode = "indeterminate";

   @Input()
   public value:    number              = 0;
}