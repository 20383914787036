import { Component }     from '@angular/core';

import { BaseComponent } from '@Base/';
import { DialogService } from '@Misc/Services/';


@Component({
    template: '',
    standalone: false
})
export class HeaderBaseComponent extends BaseComponent
{
    protected readonly mDialogHeight: string;// = 75; // %
    protected readonly mDialogWidth:  string;// = 85; // %


    constructor(private readonly DialogService: DialogService)
    {
        super();
    }


    //
    // Getters
    //

    // Override
    protected get component(): any
    {
        return undefined;
    }


    //
    // Public methods
    //
    public onClick(event: any): void
    {
        this.show(this.component);
    }


    //
    // Private methods
    //
    private show(component: any,
                 x:         number | string = this.mDialogWidth,
                 y:         number | string = this.mDialogHeight): void
    {
        if (this.DialogService && component) this.DialogService.showDialog(component, y, x, undefined);
    }
}