import {
    AfterContentChecked,
    AfterContentInit,
    AfterViewChecked,
    AfterViewInit,
    Component,
    DoCheck,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
}                        from '@angular/core';

import { AppStrings }    from '@Root/';

import { Base }          from './base.class';


@Component({
    template: '',
    standalone: false
})
export class BaseComponent extends Base implements
    AfterContentChecked,
    AfterContentInit,
    AfterViewChecked,
    AfterViewInit,
    DoCheck,
    OnChanges,
    OnDestroy,
    OnInit
{
    //
    // Getters
    //
    public get AppStrings(): typeof AppStrings
    {
        return AppStrings;
    }

    
    //
    // Anuglar hooks, in order of calling at component start-up
    //
    

    // 1
    public ngOnChanges(changes: SimpleChanges): void
    {
        
    }


    // 2
    public ngOnInit(): void
    {
        this.initialise();
    }


    // 3
    public ngDoCheck(): void
    {

    }


    // 4
    public ngAfterContentInit(): void
    {

    }


    // 5
    public ngAfterContentChecked(): void
    {

    }

 
    // 6
    public ngAfterViewInit(): void
    {
        //this.initialise(); // can be much quicker here
    }


    // 7
    public ngAfterViewChecked(): void
    {
    
    }


    // 8
    public ngOnDestroy(): void
    {
        this.cleanUp(); // In parent
    }
}