import {
    Component,
    Input
}                                   from '@angular/core';

import { MaterialCustomModule }     from '@Material/';

import { ButtonBaseComponent }      from './button-base.component';
import { ProgressSpinnerComponent } from './progress-spinner.component';

// mat-button
import { GuiElementsModule }        from './guielements.module';


@Component({
    selector:    'qp-button',
    templateUrl: 'button.component.html',
    styleUrls:   [ 'button.component.css' ],
    imports:     [
        GuiElementsModule,
        MaterialCustomModule,

        ProgressSpinnerComponent
    ]
})
export class ButtonComponent extends ButtonBaseComponent
{
    @Input()
    public icon: string;
}