import {
    Component,
    Input
}                        from '@angular/core';

import { BaseComponent } from '@Base/';
import { IconsMaterial } from '@Icons/';
// import { Notification }  from '@ObjElements/';
import { Notification }      from '@ObjElements/notification/';


// Values here need to match associated CSS
export enum NotificationIconState {
    Low    = 'low',
    Medium = 'medium',
    High   = 'high',
    None   = 'none'
}; // NotificationIconState


@Component({
   selector:     'qp-notification-icon',
   templateUrl:  'notification-icon.component.html',
   styleUrls:   ['notification-icon.component.css'],
   standalone: false
})
export class NotificationIconComponent extends BaseComponent
{
    public readonly icon:  string                = IconsMaterial.notifications;
    
    private        _state: NotificationIconState = NotificationIconState.None;


    public get stateI(): NotificationIconState
    {
        return this._state;
    }

    @Input()
    public set state(d: NotificationIconState)
    {
        if (d) this._state = d;
    }

    
    // Allow setting by ElementState
    // @Input()
    // public set stateE(d: ElementState)
    // {
    //     this._state = StatusIndicatorComponent.getState(d);
    // }
    

    // Values here need to match associated CSS
    // public static readonly states = {
    //     good:    'good',
    //     average: 'average',
    //     poor:    'poor',
    //     none:    'none',
    //     unknown: 'unknown'
    // };


    
// const _NotificationSeverity = {
//     low:    'low',
//     medium: 'medium',
//     high:   'high',
//     none:   'none',
// }; // _NotificationSeverity


    private static getState(d: any): NotificationIconState
    {
        if (d) switch (d) {
            case Notification.NotificationSeverity.low:    return NotificationIconState.Low;
            case Notification.NotificationSeverity.medium: return NotificationIconState.Medium;
            case Notification.NotificationSeverity.high:   return NotificationIconState.High;

            case Notification.NotificationSeverity.none:
            default:
                // Fall through
        } // switch

        return NotificationIconState.None;
    }
}