@if (enabled) {
    <button mat-stroked-button
        [color]="'primary'"
        [matTooltip]="label"

        (click)="onClick($event)" 
    >
        <mat-icon [attr.aria-label]="label">{{ icon }}</mat-icon>
        @if (loading) {
            <qp-progress-spinner
                [diameter]=25
            >
            </qp-progress-spinner>
        }
        @else {
            {{ name }}
        }
    </button>
}