import { Injectable }  from '@angular/core';
// import { Router }      from '@angular/router';
import {
    BehaviorSubject,
    Observable,
    Subject,
    Subscription
}                      from 'rxjs';

import { BaseService } from '@Base/';
import { Utils }       from '@Utils/';

import { routeNames }  from '../app.routing.names';
// import { ClientConfigService }   from '@Utils/';

import { User }        from '@Common/';

export { User }        from '@Common/';


@Injectable({
    providedIn: 'root'
})
export class UserService extends BaseService
{
    private static readonly currentUserKey:                 string = 'currentUser';
    private static readonly optionwebLogoutOnDisconnectKey: string = 'webLogoutOnDisconnect';
    private static readonly optionWebTimeoutKey:            string = 'webTimeout';

    private static readonly offset:                         number = -5; // number of secs to adjust expiry timeout by

    private _timer$:                                        Observable<number> | Subscription | undefined;
    private _user$:                                         BehaviorSubject<User | undefined>;
    private _userTimeout$:                                  Subject<boolean>;
    
    private _webLogoutOnDisconnect:                         boolean; // don't set value here
    private _webTimeout:                                    boolean; // don't set value here

    
    constructor()//private readonly Router: Router)
                // private readonly ClientConfigService: ClientConfigService)
    {
        super();

        this._user$                 = new BehaviorSubject<User | undefined>(undefined);
        this._userTimeout$          = new Subject<boolean>();

        this._webLogoutOnDisconnect = false;
        this.webTimeout             = true; // call setter

        const u: User | undefined = this.processUser(this.get());
        if (u instanceof User && u.token) {
            console.info("User details retrieved from localStorage");
            console.debug(u);

            // if (! this.processOptions(u.options)) {
            //     // if (this.webTimeout) this.startTimer(u);
            // }
            // this.updateSub(u);
        }
        // else {
        //     this.clear();
        // }

        // this.sub = this.ClientConfigService.webTimeoutEnabled
        // .subscribe((required: boolean) => {
        //     if (required) {
        //         console.debug("Web timeout is enabled");
        //         this.webTimeout = true;
        //         if (! this._timer$) this.startTimer(user);
        //         // Web timeout enabled
        //     }
        //     else {
        //         // Web timeout disabled
        //         this.webTimeout = false;
        //         this.clearTimer();
        //     }
        // }); // subscribe
    }


    //
    // Getters
    //
    public get user$(): Observable<User | undefined> | undefined
    {
        return this._user$ instanceof Subject ? this._user$.asObservable() : undefined;
    }


    public get userTimeout$(): Observable<boolean> | undefined
    {
        return this._userTimeout$ instanceof Subject ? this._userTimeout$.asObservable() : undefined;
    }


    public get webLogoutOnDisconnect(): boolean
    {
        return this._webLogoutOnDisconnect;
    }


    private get webTimeout(): boolean
    {
        return this._webTimeout;
    }

    private set webTimeout(d: boolean)
    {
        console.debug("Webtimeout: current = " + this.webTimeout + ", new = " + d);
        if (d != this._webTimeout) {
            if (this._webTimeout = d) {
                console.debug("Webtimeout: about to start");
                if (! this._timer$) {
                    this.startTimer(this.get());
                    console.debug("Webtimeout: started");
                }
            }
            else {
                this.clearTimer();
                console.debug("Webtimeout: stopped");
            }
        }
    }


    //
    // Public functions
    //
    public logout(): void
    {
        this.update();
    }


    public update(d?: object): User | undefined
    {
        // Will clear existing user if no data passed in
        const u: User | undefined = this.processUser(User.get(d));
        return (u instanceof User && u.isValid) ? this.store(u) : u;

// console.debug(user);
//             if (! this.processOptions(user.options)) {
//                 // if (this.webTimeout) this.startTimer(user);
//             }
        //     return this.store(user);
        // }

        // return this.clear();
    }


    public processUser(u: User | undefined): User | undefined
    {
        if (u instanceof User && u.isValid) {
            this.webTimeout = true; // will start timer

            this.processOptions(u.options);
            this.updateSub(u);
        }
        else {
            u = this.clear();
        }

        return u;
    }


    //
    // Protected functions
    //

    // Override
    protected override cleanUp(): void
    {
        super.cleanUp();

        this.clearTimer();
    }


    //
    // Private functions
    //
    private clear(): User | undefined
    {
        this.clearTimer();
        
        // Clear user details and JWT token from local storage
        if (localStorage) localStorage.removeItem(UserService.currentUserKey);
        else              console.warn("Localstorage not supported (1)");
        
        const u: User | undefined = User.get({});
        return u instanceof User ? this.updateSub(u) : undefined; // send empty User object, not undefined!
    }


    private clearTimer(): void
    {
        if (this._timer$ instanceof Subscription) {
            this._timer$.unsubscribe();
            // clearTimeout(this._timer);
            this._timer$ = undefined;
        }
    }


    private get(): User | undefined
    {
        if (localStorage) {
            try {
                const s: string | null = localStorage.getItem(UserService.currentUserKey);
                if (s) return User.get(JSON.parse(s));
            } 
            catch (e) {
                console.warn(JSON.stringify(e));
            }
        }
        else {
            console.warn("Localstorage not supported (2)");
        }

        return undefined;
    }


    private processOptions(d?: []): boolean
    {
        let ret: boolean = false; // used to inform whether webTimer option has been received or not

        if (Array.isArray(d)) d.forEach((s: any) => {
            console.debug(s);
            if (s) switch (s.name) {
                 case UserService.optionWebTimeoutKey:
                    this.webTimeout = ! (s.value == "false");
                    ret = true;
                    break;

                case UserService.optionwebLogoutOnDisconnectKey:
                    this._webLogoutOnDisconnect = ! (s.value == "false");
                    break;
            } // switch
        }); // forEach

        return ret;
    }


    private startTimer(u: User | undefined) : void
    {
        if (u instanceof User) {
            let expiry = Math.round((
                (
                    (u)
                    ? +u.expiresIn - (Date.now() / 1000)
                    : 0
                ) * 100) / 100);
            
            if (expiry > 0) {
                expiry += (expiry > +UserService.offset ? UserService.offset : 0);

                console.info("Setting timer for token expiry in " + expiry + " secs");
                // if (this._timer$) clearTimeout(this._timer$);
                if (this._timer$) this.clearTimer();

                this._timer$ = Utils.delaySecs(() => {
                    console.info("Token has expired after " + expiry + " secs; redirecting to login screen");
                    if (this._userTimeout$ instanceof Subject) this._userTimeout$.next(true);
                    this._timer$ = undefined;
                }, expiry);

                // this._timer$ = setTimeout((): void => {
                //     console.info("Token has expired after " + expiry + " secs; redirecting to login screen");
                //     this.clear();
                //     this.Router.navigate([routeNames.login], {});
                //     this._timer$ = undefined;
                // }, expiry * 1000);
            }
        }
    }


    private store(u: User): User
    {
        // Store user details and JWT token in local storage
        if (localStorage) localStorage.setItem(UserService.currentUserKey, JSON.stringify(u));
        else              console.warn("Localstorage not supported (3)");

        return u;//this.updateSub(u);
    }


    private updateSub(u: User): User
    {
        if (this._user$ instanceof BehaviorSubject) this._user$.next(u);
        else                                        this._user$ = new BehaviorSubject<User | undefined>(u);    
        
        return u;
    }
}