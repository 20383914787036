import {
    Component,
    EventEmitter,
    Input,
    Output
}                        from '@angular/core';

import { BaseComponent } from '@Base/';


@Component({
    template: '',
    standalone: false
})
export abstract class ButtonBaseComponent extends BaseComponent
{
    @Input()
    public enabled: boolean = true;


    @Input()
    public label:   string;


    @Input()
    public name:   string;
    
    
    @Input()
    public loading: boolean;


    @Output()
    // deprecated
    public pressed: EventEmitter<boolean> = new EventEmitter(); // used to inform parents that button pressed

    @Output()
    public clickL: EventEmitter<boolean> = new EventEmitter(); // used to inform parents that button pressed

    

    //
    // Public functions
    //

    // deprecated
    public button(): void
    {
        this.pressedFn();
    }

    public onClick(ev?: any): void
    {
        this.pressedFn();
    }


    //
    // Private functions
    //
    private pressedFn(): void
    {
        this.pressed.emit(true); // deprecated
        this.clickL.emit(true);
    }
}