import {
    Component,
    OnInit
}                              from '@angular/core';
import { Observable }          from 'rxjs';

import { BaseComponent }       from '@Base/';
import { ClientConfigService } from '@Misc/Services/';
//declare function require(name: string): string;


@Component({
   selector:     'qp-footer',
   templateUrl:  'footer.template.html',
   styleUrls:   ['footer.component.css'],
   standalone: false
})
export class HeaderFooterBaseComponent extends BaseComponent
{
    public          company:    string | undefined;
    public          companyUrl: string | undefined;
    public          name:       string | undefined;
    public          version:    string | undefined;
    public readonly today:      Date     = new Date();


    constructor(private readonly ClientConfigService: ClientConfigService)
    {
        super();
    }


    //
    // Protected functions
    //
    protected override initialise(): void
    {
        super.initialise();

        if (this.ClientConfigService) {
            const obs: Observable<any> | undefined = this.ClientConfigService.version$;
            if (obs instanceof Observable) this.sub = obs
                .subscribe((d: any): void => this.update(d)
            ); // subscribe
        }

        // const vers = this.ClientConfigService.version;
        // if (vers) {
        //     this.company    = vers.getCompany();//this.VersionService.getCompany();
        //     this.companyUrl = vers.getCompanyUrl();
        //     this.name       = vers.getName();
        // }
    }


    private update(v: any)
    {
        if (v) {
            this.company    = v.getCompany();//this.VersionService.getCompany();
            this.companyUrl = v.getCompanyUrl();
            this.name       = v.getName();
            this.version    = v.getVersion();
        }
    }
}