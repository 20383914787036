import {
    Component,
    Input
}                               from '@angular/core';

import { BaseComponent }        from '@Base/';
import { IconsMaterial }        from '@Icons/';
import { StatusIndicatorState } from './status-indicator.component';


@Component({
   selector:     'qp-status-icon',
   templateUrl:  'status-icon.component.html',
   styleUrls:   ['status-icon.component.css'],
   standalone: false
})
export class StatusIconComponent extends BaseComponent
{
    public  icon:   string                = IconsMaterial.status_unknown;

    private _state: StatusIndicatorState;


    //
    // Getters and setters
    //
    @Input()
    public get state(): StatusIndicatorState
    {
        return this._state
    }

    public set state(d: StatusIndicatorState)
    {
        switch (this._state = d) {
            case StatusIndicatorState.Good:
                this.icon = IconsMaterial.status_good;
                break;

            case StatusIndicatorState.Average:
                this.icon = IconsMaterial.status_average;
                break;

            case StatusIndicatorState.Poor:
                this.icon = IconsMaterial.status_poor;
                break;

            default:
                this.icon = IconsMaterial.status_unknown;
        } // switch
    }
}