<!-- @if (enabled) {
    <button mat-fab color="primary" [matTooltip]="label"
        (click)="onClick($event)" 
    >
        <mat-icon [attr.aria-label]="label">{{ icon }}</mat-icon>
    </button>
} -->

<qp-button
    [enabled]="enabled"
    [icon]="icon"
    [label]="label"
    [loading]="loading"
    [name]="name"
    
    (clickL)="onClick($event)" 
/>