import {
    Component,
    Input
}                        from '@angular/core';

import { BaseComponent } from '@Base/';


@Component({
   selector:     'qp-data-collection',
   templateUrl:  'data-collection.component.html',
   styleUrls:   ['data-collection.component.css'],
   standalone: false
})
export class DataCollectionComponent extends BaseComponent
{
    @Input()
    public small: boolean = false;
}