import {
    Component,
    OnInit
}                        from '@angular/core';

import { BaseComponent } from '@Base/';
import { IconsMaterial } from '@Icons/';


@Component({
    selector:    'qp-header-settings',
    templateUrl: 'header-settings.component.html',
    standalone: false
})
export class HeaderSettingsComponent extends BaseComponent
{
    public readonly icon: string = IconsMaterial.settings;

    public readonly enabled = false;
    

    public onClick(event: any): void
    {
        //console.log("Button pressed - displaying Settings");
        this.show();
    }


    private show(): void
    {
        console.log("[TBD] showing Settings");
    }


    // Override
    protected override initialise(): boolean
    {
        super.initialise();
        
        console.debug("Initialising HeaderSettings component");

        return true;
    }
}