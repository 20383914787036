import {
    CommonModule,
    NgOptimizedImage
}                                            from '@angular/common';
import { NgModule }                          from '@angular/core';
import { FlexLayoutModule }                  from '@angular/flex-layout';
import { RouterModule }                      from '@angular/router';

import { MaterialCustomModule }              from '@Material/';

import { BatteryLevelIconComponent }         from './battery-level-icon.component';
// import { ButtonComponent }                   from './button.component';
import { ConnectionIconComponent }           from './connection-icon.component';
import { DataCollectionComponent }           from './data-collection.component';
import { DataElementComponent }              from './data-element.component';
import { GeolocationButtonComponent }        from './geolocation-button.component';
import { ImageButtonComponent }              from './image-button.component';
import { ImageListComponent }                from './image-list.component';
import { ListDataComponent }                 from './list-data.component';
import { MapButtonComponent }                from './map-button.component';
import { MenuComponent }                     from './menu.component';
import { MobileCellLevelIconComponent }      from './mobilecell-level-icon.component';
import { MobileCellTechnologyIconComponent } from './mobilecell-technology-icon.component';
import { NotificationIconComponent }         from './notification-icon.component';
import { PowerButtonComponent }              from './power-button.component';
import { RefreshButtonComponent}             from './refresh-button.component';
import { RouteBackComponent }                from './route-back.component';
import { StatusIconComponent }               from './status-icon.component';
import { StatusIconSurveyComponent }         from './status-icon-survey.component';
import { StatusIndicatorComponent }          from './status-indicator.component';
import { TableDataComponent }                from './table-data.component';
import { TitleComponent }                    from './title.component';
import { WifiCellVersionIconComponent }      from './wificell-version-icon.component';
import { WindowHeaderComponent }             from './window-header.component';
import { WindowTitleComponent }              from './window-title.component';

// Standalone
// import { ProgressSpinnerComponent }          from './progress-spinner.component';
// import { ReportButtonComponent }             from './report-button.component';


@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        RouterModule,

        MaterialCustomModule,
        NgOptimizedImage,

        // Standalone
        // ProgressSpinnerComponent
        // ReportButtonComponent
    ],
    
    declarations: [
        BatteryLevelIconComponent,
        // ButtonComponent,
        ConnectionIconComponent,
        DataCollectionComponent,
        DataElementComponent,
        GeolocationButtonComponent,
        ImageButtonComponent,
        ImageListComponent,
        ListDataComponent,
        MapButtonComponent,
        MenuComponent,
        MobileCellLevelIconComponent,
        MobileCellTechnologyIconComponent,
        NotificationIconComponent,
        PowerButtonComponent,
        RefreshButtonComponent,
        RouteBackComponent,
        StatusIconComponent,
        StatusIconSurveyComponent,
        StatusIndicatorComponent,
        TableDataComponent,
        TitleComponent,
        WifiCellVersionIconComponent,
        WindowHeaderComponent,
        WindowTitleComponent
    ],

    providers: [
    ],

    exports: [
        //ButtonComponent, // don't export
        BatteryLevelIconComponent,
        ConnectionIconComponent,
        DataCollectionComponent,
        DataElementComponent,
        GeolocationButtonComponent,
        ImageButtonComponent,
        ImageListComponent,
        ListDataComponent,
        MapButtonComponent,
        MenuComponent,
        MobileCellLevelIconComponent,
        MobileCellTechnologyIconComponent,
        NotificationIconComponent,
        PowerButtonComponent,
        RefreshButtonComponent,
        RouteBackComponent,
        StatusIconComponent,
        StatusIconSurveyComponent,
        StatusIndicatorComponent,
        TableDataComponent,
        TitleComponent,
        WifiCellVersionIconComponent,
        WindowHeaderComponent,
        WindowTitleComponent,

        // Standalone
        // ProgressSpinnerComponent,
        // ReportButtonComponent,
    ]
})
export class GuiElementsModule { }